export default class Category {
  constructor(item) {
    this.id = item.id;
    this.name = item.name;
    this.count = item.count;
    this.description = item.description;
    this.taxonomy = item.taxonomy;
    this.parent = item.parent;
    this.slug = item.slug;
  }
}
