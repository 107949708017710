import Placeholder from '../../config/placeholder.config';

export default class Venue {
  constructor(item) {

  }

  static getImage(venue) {
    let imageUrl = null; // Placeholder.venue;
    if (venue.profile_image_url) {
      if (venue.profile_image_url.indexOf('http') === -1) {
        imageUrl = `https://${venue.profile_image_url}`;
      } else {
        imageUrl = venue.profile_image_url;
      }
    } else if (venue.logo_image_url) {
      if (venue.logo_image_url.indexOf('http') === -1) {
        imageUrl = `https://${venue.logo_image_url}`;
      } else {
        imageUrl = venue.logo_image_url;
      }
    }
    // INFO This can be removed - it's handled server side
    // if (!imageUrl && venue.images && venue.images.length) {
    //   for (let i = 0; i < venue.images.length; i++) {
    //     if (venue.images[i].external_id_type === 'google') {
    //       imageUrl = venue.images[i].url.replace('/venues/', '/100x100/venues/');
    //       break;
    //     }
    //   }
    // }
    return imageUrl;
  }

  static getCoverImage(venue) {
    let imageUrl = Placeholder.covers.venue;
    if (venue.cover_image_url) {
      if (venue.cover_image_url.indexOf('http') === -1) {
        imageUrl = `https://${venue.cover_image_url}`;
      } else {
        imageUrl = venue.cover_image_url;
      }
    }
    return imageUrl;
  }
}
