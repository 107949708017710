import Placeholder from '../../config/placeholder.config';

export default class Drink {
  constructor(item) {}
}

Drink.getImage = (drink) => {
  if (drink) {
    let imageUrl;
    if (drink.image_url) {
      imageUrl = drink.image_url;
    } else if (drink.image) {
      imageUrl = drink.image;
    }
    if (imageUrl) {
      if (imageUrl.indexOf('ei.isnooth.com') !== -1) {
        imageUrl = imageUrl.replace('ei.isnooth.com/multimedia', 'snooth.boxpressd.io');
      }
      if (imageUrl.indexOf('snooth.sigaro.io') !== -1) {
        imageUrl = imageUrl.replace('snooth.sigaro.io', 'snooth.boxpressd.io');
      }
      if (imageUrl.indexOf('http:') !== -1) {
        imageUrl = imageUrl.replace('http:', 'https:');
      }
      return imageUrl;
    }
  }
  return Placeholder.drink;
};
