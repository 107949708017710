/*
    {
      id: hashids.encode(item.id), // Hash ID of the object
      title: 'Share Title',
      text: 'X shared their virtual humidor with you.',
      image: 'some url',
      video: 'some url',
      path: 'article', // Branch.io path
      route: `/articles/${hashids.encode(item.id)}`,
    }
 */
export default class ShareItem {
  constructor(item) {
    if (item) {
      Object.assign(this, item);
    }
  }
}
