// import URI from 'urijs';
// import truncate from 'lodash/truncate';

// const env = process.env.NODE_ENV || 'development';
// const config = require('config/config.json')[env];

// FIXME These are WP Comments - not comments for the Feed (sessions, checkins, etc). May want to rename to distinguish
//  or reuse with a flag for the constructor (type='' or similar)
export default class Comment {
  constructor(item) {
    this.id = item.id;
    this.author_id = item.author;
    this.author_name = item.author_name;
    this.content = item.content.rendered;
    this.date = item.date;
    this.parent = item.parent;
    this.post = item.post;
    this.type = item.type; // INFO I believe we only want comments, not tracebacks or pingbacks
    this.meta = item.meta;
    // TODO Author image url?
    this.author_avatar_url = item.author_avatar_urls['48'];

    // Custom fields

    console.log(item);
  }
}
