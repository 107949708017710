import React, { useEffect, useState } from 'react';
import { isMobile } from 'mobile-device-detect';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import { config } from '../../settings';

function NotFoundPage({ history }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const path = window.location.pathname;
    if (path.replace('/', '').indexOf('/') === -1) {
      axios.get(`${config.apiEndPoint}/businesses/handles/${path.replace('/', '')}`).then((response) => {
        const { data } = response;
        if (data.business_type && data.business_hash_id) {
          // FIXME Can we redirect but keep the URL?
          history.replace(`${data.business_type}s/${data.business_hash_id}`);
        } else {
          setLoading(false);
        }
      }).catch((e) => {
        console.error(e);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return null; // TODO Something?
  }
  return (
    <PageWrap style={{ textAlign: 'center' }}>
      <PageTitle
        breadcrumbs={isMobile ? {} : {
          '/': 'Home',
        }}
      >
        <h1>404</h1>
      </PageTitle>
      <PageContent>
        <AsyncComponent component={() => import('./content')} />
      </PageContent>
    </PageWrap>
  );
}

export default withRouter(NotFoundPage);
