import URI from 'urijs';
import truncate from 'lodash/truncate';

const env = process.env.NODE_ENV || 'development';
const config = require('../../config/config.json')[env];

// TODO May be better off naming this Article or similar - we may still want to allow our users to add Posts (not WP posts like these)
export default class Post {
  constructor(item) {
    this.id = item.id;
    this.title = item.title.rendered;
    this.content = item.content.rendered;
    this.categories = item.categories;
    this.excerpt = this.getContent(item.excerpt.rendered, 100);
    this.image = this.getImage(item, 'medium_large');
    this.largeImage = this.getImage(item, 'full');
    this.imageAttribution = this.getImageAttribution(item);
    this.videoId = this.getVideoId(item.content.rendered);
    this.videoUrl = `www.youtube.com/embed/${this.videoId}`;
    this.videoImage = `http://img.youtube.com/vi/${this.videoId}/0.jpg`;
    this.author = this.getAuthorName(item);
    this.authorImage = item.authorImage || null;
    this.intro = this.getContent(this.content, 300);
    this.longIntro = this.getContent(this.content, 800);

    // FIXME The spelling on these
    this.categorieName = this.getCategorie(item);
    this.categorieName = this.getCategorie(item);
    this.categorieSlug = this.getCategorie(item, 'slug');
    this.date = item.date;

    // Custom fields
    this.cigar_id = item.cigar_id;
    this.venue_id = item.venue_id;
    this.podcast_player_url = item.podcast_player_url;

    // console.log('video id', item.content.rendered, this.videoId);
    // console.log(item);
  }

  getContent(text, characters = 200) {
    // const textFormat = this.htmlEntities(text.replace(/<\/?[^>]+(>|$)/g, ''));
    return truncate(text, { length: characters, separator: ' ' });
  }

  htmlEntities(str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  }

  getImage(data, imageSize) {
    const post = data;
    /* eslint-disable no-underscore-dangle */
    const embedded = post._embedded;
    /* eslint-enable */

    let imageType = imageSize;

    if (typeof data === 'undefined' || data === null) {
      return '';
    }
    if (typeof imageSize === 'undefined') {
      imageType = 'medium_large';
    }

    let imageURL = typeof data.better_featured_image !== 'undefined' && data.better_featured_image != null ? data.better_featured_image.source_url : '';

    if (typeof embedded !== 'undefined') {
      if (typeof embedded['wp:featuredmedia'] !== 'undefined') {
        if (typeof embedded['wp:featuredmedia'][0].media_details !== 'undefined') {
          if (typeof embedded['wp:featuredmedia'][0].media_details.sizes !== 'undefined') {
            if (typeof embedded['wp:featuredmedia'][0].media_details.sizes[imageType] !== 'undefined') {
              imageURL = embedded['wp:featuredmedia'][0].media_details.sizes[imageType].source_url;
            }
          }
        }
      }
    }

    if (typeof config.CDNUrl !== 'undefined' && config.CDNUrl !== '') {
      imageURL = imageURL.replace(config.wpUrl, config.CDNUrl);
    }

    return imageURL;
  }

  getImageAttribution(post) {
    const embedded = post._embedded;
    if (typeof embedded !== 'undefined') {
      if (typeof embedded['wp:featuredmedia'] !== 'undefined') {
        if (typeof embedded['wp:featuredmedia'][0].caption !== 'undefined') {
          return embedded['wp:featuredmedia'][0].caption.rendered;
        }
      }
    }
    return null;
  }

  getCategorie(data, type = 'name') {
    /* eslint-disable no-underscore-dangle */
    const embedded = data._embedded;
    /* eslint-enable */

    if (embedded) {
      if (type === 'name') {
        return embedded['wp:term'][0][0].name;
      } else if (type === 'slug') {
        return embedded['wp:term'][0][0].slug;
      }
      return embedded['wp:term'][0][0].name;
    }
    return null;
  }

  getVideoId(content) {
    /* eslint-disable no-useless-escape */
    const regExp = /^.*((www.youtube.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\??v?=?))([^#&\?\/\ ]*).*/;
    let embedId = null;
    /* eslint-enable */

    URI.withinString(content, (url) => {
      const match = url.match(regExp);
      if (match && match[7].length === 11) {
        embedId = match[7];
      }
    });
    return embedId;
  }

  getAuthorName(item) {
    /* eslint-disable no-underscore-dangle */
    return item._embedded && item._embedded.author && item._embedded.author[0].name;
    /* eslint-enable */
  }
}
