import Placeholder from '../../config/placeholder.config';
import PlaceholderDark from '../../config/placeholder-dark.config';
import { Transducer } from '../utils/transducer';

class Product {
  constructor(item) {
    this.id = item.id;
    this.name = item.name;
    this.description = item.description;
    this.reference = item.reference;
    this.buyUrl = item.buy_url || item.buyUrl;
    this.affiliateUrl = item.affiliate_url || item.affiliateUrl;
    this.imageUrl = item.image_url || item.imageUrl;
    this.msrp = item.msrp;
    // this.price = item.price;
    this.salePrice = item.sale_price || item.salePrice;
    this.ean13 = item.ean13;
    this.auction = item.auction;
    this.stockCount = item.stock_count || item.stockCount;
    this.outOfStock = item.out_of_stock || item.outOfStock;
    this.availability = item.availability;
    this.images = item.images;
    this.packaging = item.packaging;
    this.contents = item.contents;
    this.vitola = item.vitola;
    this.venue = item.venue; // FIXME Wrap in Venue object class
  }

  getMainImage() {
    let imageUrl = Placeholder.cigar;
    if (this.imageUrl) {
      if (this.imageUrl.indexOf('http') === -1) {
        imageUrl = `https://${this.imageUrl}`;
      } else {
        imageUrl = this.imageUrl;
      }
    } else if (this.images) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < this.images.length; j++) {
        imageUrl = `https://${this.images[j].url}`;
        break;
      }
    }
    return imageUrl;
  }

  getDisplayName() {
    let title = this.name;
    if (this.vitola) {
      title += ` ${this.vitola.formatted_name}`;
    }
    if (this.packaging) {
      if (this.packaging.type === 'Sampler') {
        let count = 0;
        for (let i = 0; i < this.contents.length; i++) {
          const content = this.contents[i];
          count += content.quantity;
        }
        title += ` (${this.packaging.type} of ${count})`;
      } else if (this.packaging.type === 'Single') {
        title += ` (${this.packaging.type})`;
      } else {
        title += ` (${this.packaging.type} of ${this.packaging.quantity})`;
      }
    }
    return title;
  }

  getFormattedPrice = () => {
    if (this.salePrice) {
      try {
        return `$${this.salePrice.toFixed(2)}`;
      } catch (e) {
        return `$${parseFloat(this.salePrice).toFixed(2)}`;
      }
    } else {
      try {
        return `$${this.msrp.toFixed(2)}`;
      } catch (e) {
        return `$${parseFloat(this.msrp).toFixed(2)}`;
      }
    }
  }
}

Product.getImage = (product, returnPlaceholder = true) => {
  // console.log('Getting image for product');
  // console.log(product);

  if (product.image_url) {
    if (product.image_url.indexOf('http') === -1) {
      if (product.image_url.indexOf('//') === -1) {
        return `https://${product.image_url}`;
      }
      return `https:${product.image_url}`;
    }
    return product.image_url;
  }

  if (product.images) {
    // console.log('Product images:');
    // console.log(product.images);
    if (Array.isArray(product.images) && product.images.length > 0) {
      // console.log('Product images is an array');
      // console.log(product.images[0].url);
      if (product.images[0].url.indexOf('http') === -1) {
        if (product.images[0].url.indexOf('//') === -1) {
          return `https://${product.images[0].url}`;
        }
        return `https:${product.images[0].url}`;
      }
      return product.images[0].url;
    }
    // FIXME This is an error with the SQL on the server - it should be fixed there to return an array
    if ('url' in product.images) {
      // console.log('Product images is an object');
      if (product.images.url.indexOf('http') === -1) {
        if (product.images.url.indexOf('//') === -1) {
          return `https://${product.images.url}`;
        }
        return `https:${product.images.url}`;
      }
      return product.images.url;
    }
  }

  return returnPlaceholder ? (Transducer.getPreference('dark_mode') === 'true' ? PlaceholderDark.cigar : Placeholder.cigar) : null;
};

Product.title = (product) => {
  if (product.packaging && product.packaging.type !== 'Sampler') {
    if (product.contents && product.contents.length > 0 && product.contents[0].cigar && product.contents[0].vitola) {
      return `${product.contents[0].cigar.full_name} ${product.contents[0].vitola.formatted_name}`;
    }
    if (product.contents && product.contents.length > 0 && product.contents[0].vitola) {
      return `${product.name} ${product.contents[0].vitola.formatted_name}`;
    }
  }
  return product.name;
};

Product.packageTitle = (product) => {
  let title = '';
  // if (product.vitola) {
  //   title += `${Vitola.formattedName(product.vitola)} - `;
  // }
  if (product.packaging) {
    if (product.packaging.type === 'Sampler') {
      let count = 0;
      for (let i = 0; i < product.contents.length; i++) {
        const content = product.contents[i];
        count += content.quantity;
      }
      title += `${product.packaging.type} of ${count}`;
    } else if (product.packaging.type === 'Single') {
      title += product.packaging.type;
    } else {
      title += `${product.packaging.type} of ${product.packaging.quantity}`;
    }
  }
  return title.trim();
};

Product.getVitola = (product) => {
  if (product.packaging && product.packaging.type !== 'Sampler' && product.contents && product.contents.length) {
    const { vitola } = product.contents[0];
    if (vitola) {
      return vitola.formatted_name;
    }
  }
  return null;
};

Product.getVitolaSize = (product) => {
  if (product.packaging && product.packaging.type !== 'Sampler' && product.contents && product.contents.length) {
    const { vitola } = product.contents[0];
    if (vitola) {
      return vitola.size;
    }
  }
  return null;
};

Product.getVitolaShape = (product) => {
  if (product.packaging && product.packaging.type !== 'Sampler' && product.contents && product.contents.length) {
    const { vitola } = product.contents[0];
    if (vitola) {
      return vitola.shape;
    }
  }
  return null;
};

Product.getVitolaAlias = (product) => {
  if (product.packaging && product.packaging.type !== 'Sampler' && product.contents && product.contents.length) {
    const { vitola } = product.contents[0];
    if (vitola) {
      return vitola.alias;
    }
  }
  return null;
};

Product.isVitolaBoxpressed = (product) => {
  if (product.packaging && product.packaging.type !== 'Sampler' && product.contents && product.contents.length) {
    const { vitola } = product.contents[0];
    if (vitola && vitola.alias) {
      return vitola.alias.indexOf('Box-Pressed') !== -1;
    }
  }
  return null;
};

Product.isVitolaTubo = (product) => {
  if (product.packaging && product.packaging.type !== 'Sampler' && product.contents && product.contents.length) {
    const { vitola } = product.contents[0];
    if (vitola && vitola.alias) {
      return vitola.alias.indexOf('Tubo') !== -1;
    }
  }
  return null;
};

Product.formatPrice = (product) => {
  const price = product.salePrice || product.sale_price || product.price;
  try {
    return `$${price.toFixed(2)}`;
  } catch (e) {
    try {
      console.log(e);
      return `$${parseFloat(price).toFixed(2)}`;
    } catch (e2) {
      console.log(e2);
      return null;
    }
  }
};

Product.formatDecimal = (price) => {
  if (typeof price === 'string') {
    price = parseFloat(price);
  }
  return price.toFixed(2);
};

export default Product;
